<template>
  <v-card
    color="white"
    tile
    flat
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        py-3
      />

      <v-flex
        v-show="showMessagePromo()"
        text-xs-center
        xs10
        md8
        lg4
      >
        <strong
          class="text-uppercase font-size-21 font-weight-bold"
          v-text="'Claro tv pré é a opção inteligente que cabe no seu bolso'"
        />
      </v-flex>

      <v-flex
        xs12
        py-3
      />

      <v-flex
        text-xs-center
        xs10
      >
        <strong
          v-font-size="18"
          class="font-weight-bold text-uppercase"
          v-text="'Veja como é simples curtir a sua:'"
        />
      </v-flex>

      <v-flex
        xs12
        py-3
      />

      <v-flex
        xs10
      >
        <v-layout
          wrap
          justify-center
        >
          <v-flex
            v-for="(next, index) in faq"
            v-show="$vuetify.breakpoint.smAndDown ? String(option.selected).includes(index) : true"
            :key="`card-instalacao-${index}`"
            xs12
            md3
          >
            <v-card
              tile
              flat
              min-height="200"
            >
              <v-layout
                justify-center
                align-center
                fill-height
                wrap
              >
                <v-flex
                  xs12
                >
                  <v-img
                    :src="next.icon"
                    :height="100"
                    :width="100"
                    class="mx-auto"
                  />
                </v-flex>

                <v-flex
                  xs8
                  text-xs-center
                >
                  <span
                    v-text="next.text"
                  />
                </v-flex>
              </v-layout>

              <v-card-actions
                v-if="next.dialog || next.anchor || next.href"
                class="pa-0 ma-0"
              >
                <v-layout
                  wrap
                  justify-center
                >
                  <v-flex
                    v-for="(button, b) in (next.dialog || next.anchor || next.href)"
                    :key="b"
                    xs8
                  >
                    <v-btn
                      class="text-none blue--text underline font-size-13 px-0"
                      flat
                      round
                      large
                      block
                      @click="next.dialog && openFaq(button.action) || next.anchor && goToAnchorDelay(button.action, 160) || next.href && openLink(next.href[b].href)"
                      v-text="button.text"
                    />
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex
            v-if="$vuetify.breakpoint.smAndDown"
            xs12
          >
            <v-tabs
              v-model="option.selected"
              show-arrows
              hide-slider
              centered
            >
              <v-tab
                v-for="next in faq.length"
                :key="`botao-instalacao-${next}`"
                :ripple="false"
              >
                <v-btn
                  icon
                  class="ma-0 pa-0"
                >
                  <v-icon>fiber_manual_record</v-icon>
                </v-btn>
              </v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<style lang="stylus">
  .fix-carousel-delimiters
    .v-carousel__controls
      background-color transparent
      .v-btn
        color #cccccc
        .v-btn__content
          .v-icon
            font-size 24px !important
      .v-btn--active
        color grey
      .v-btn--active:before
        background-color transparent

  .fix-side-icon
    .v-btn__content
      background-color #eeeeee

  .fix-skew-effect
    .skew-flag-left::after
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      z-index: -1;
      bottom: -4px;
      left: 2px;
      background-color: #eeeeee;
      transform: skew(-32deg) rotate(-32deg);
    .skew-flag-right::after
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      z-index: -1;
      bottom: -4px;
      right: 2px;
      background-color: #eeeeee;
      transform: skew(32deg) rotate(32deg)
</style>

<script>
  import { mapActions } from 'vuex'

  export default {
    data: function () {
      return {
        option: {
          selected: 0
        },
        faq: [
          {
            icon: require('@/assets/images/howto/icons/adiquira-seu-receptor.png?lazy'),
            text: 'Compre o equipamento e ele passa a ser seu.'
          },
          {
            icon: require('@/assets/images/howto/icons/contrate-tecnico.png?lazy'),
            text: 'Localize e contrate um instalador.'
          },
          {
            icon: require('@/assets/images/howto/icons/recarregue-quanto-puder.png?lazy'),
            text: 'Agora é só recarregar e curtir a melhor programação abaixo.'
          }
        ]
      }
    },

    methods: {
      ...mapActions('Dialogs', [ 'openFaq' ]),

      goToAnchorDelay (anchor, time) {
        setTimeout(() => {
          this.$vuetify.goTo(anchor)
        }, time)
      },

      showMessagePromo () {
        return this.$route.meta.promo
      },

      openLink (link) {
        window.open(link, '_blank')
      }
    }
  }
</script>
