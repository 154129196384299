<template>
  <v-card
    class="gotoFaq"
    color="white"
    tile
    flat
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        py-3
      />

      <v-flex
        text-xs-center
        xs10
      >
        <strong
          v-font-size="18"
          class="font-weight-bold text-uppercase"
          v-text="'Perguntas frequentes'"
        />
      </v-flex>

      <v-flex
        xs12
        py-3
      />

      <v-flex
        xs12
      >
        <v-layout
          justify-center
          wrap
        >
          <v-flex
            xs11
            md10
            lg8
          >
            <v-layout
              justify-center
              wrap
            >
              <v-flex
                v-height.max="expansionPanelHiddenFaqContent ? 300 : '100%'"
                v-overflow="expansionPanelHiddenFaqContent ? 'hidden' : 'auto'"
                v-cursor="expansionPanelHiddenFaqContent ? 'pointer' : 'default'"
                text-xs-center
                xs12
                :class="expansionPanelHiddenFaqContent && 'fix-expansionpanelhiddenfaqcontent'"
                @click.stop="expansionPanelHiddenFaqContent && (expansionPanelHiddenFaqContent = !expansionPanelHiddenFaqContent)"
              >
                <v-expansion-panel
                  v-for="(next, i) in faq"
                  :key="i"

                  :class="`mb-4 flat border-radius-8`"
                  :style="`border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75);`"
                >
                  <v-expansion-panel-content
                    class="fix-expansion-panel transparent"
                  >
                    <template
                      v-slot:header
                    >
                      <div
                        :style="`font-size: 16px !important; font-weight: 400 !important`"
                      >
                        {{ next.i }}
                      </div>
                    </template>

                    <template
                      v-slot:actions
                    >
                      <v-icon
                        color="grey"
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>

                    <v-card>
                      <v-card-text
                        :style="`font-size: 16px !important; font-weight: 400 !important`"
                      >
                        <span
                          v-html="next.text"
                        />

                        <span
                          v-if="next.anchor"
                          class="underline blue--text cursor-pointer"
                          @click="goToAnchorDelay(next.anchor[0].action, 160)"
                        >
                          {{ next.anchor[0].text }}
                        </span>

                        <span
                          v-if="next.dialog"
                          @click="openFaq(next.dialog[0].action)"
                          v-html="next.dialog[0].text"
                        />
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>

              <v-flex
                xs12
              />

              <v-flex shrink>
                <v-btn
                  block
                  round
                  depressed
                  dark
                  color="#D52B1E"
                  @click.stop="expansionPanelHiddenFaqContent = !expansionPanelHiddenFaqContent"
                >
                  <v-icon v-text="expansionPanelHiddenFaqContent ? 'keyboard_arrow_down' : 'close'" />
                  <span v-text="expansionPanelHiddenFaqContent ? 'Ver mais' : 'Fechar'" />
                </v-btn>
              </v-flex>

              <v-flex
                xs12
                py-4
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        xs11
        md10
        lg8
      >
        <v-expansion-panel
          class="mb-4 flat border-radius-8"
        >
          <v-expansion-panel-content class="fix-expansion-panel--legal-text">
            <template v-slot:header>
              <h3
                class="font-size-16 font-weight-regular"
                v-text="'Como é a parceria entre Vertex e Claro?'"
              />
            </template>

            <template v-slot:actions>
              <v-icon color="grey">
                keyboard_arrow_down
              </v-icon>
            </template>

            <v-card>
              <v-card-text
                style="font-size: 16px !important; font-weight: 400 !important"
              >
                <span class="grey-bold--text">
                  Vertex Digital é uma Salestech especializada em vendas online credenciada autorizada pela Claro para comercialização digital dos seus planos e produtos.
                </span>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<style lang="stylus">
  .fix-expansionpanelhiddenfaqcontent::after
    content: "";
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.1) 20%, #fff 100%);
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

  .fix-expansion-panel
    .v-expansion-panel__header
      min-height 62px
      padding 14px

  .fix-expansion-panel--legal-text
    .v-expansion-panel__header
      min-height 62px
      padding 14px
      border-radius: 8px
      border: 1px solid #e9e9e9
      box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)

</style>

<script>
  import { mapActions } from 'vuex'

  export default {
    data: function () {
      return {
        expansionPanelHiddenFaqContent: true,
        faq: [
          {
            anchor: [
              {
                text: 'Clique aqui para contratar.',
                action: '.gotoCard'
              }
            ],
            i: 'Preciso adquirir o receptor?',
            text: 'Sim, e esta super oferta já possui receptor incluído! Depois de comprá-lo, este equipamento passa a ser seu.'
          },

          {
            i: 'Preciso comprar antena?',
            text: `
                Caso ainda não possua, sim. Após comprar a sua antena,
                localize um técnico instalador especializado e se informe sobre o valor de instalação do equipamento.
                Este valor pode variar conforme região, distância e dificuldade da instalação.
                `
          },

          {
            i: 'Como faço para instalar a antena?',
            text: 'A antena será instalada pelo Técnico contratado.'
          },

          {
            dialog: [
              {
                text: '<span class="underline blue--text cursor-pointer">Clique aqui e confira.</span>',
                action: 'assista-canais'
              }
            ],
            i: 'Quais são os planos de tv disponíveis?',
            text: ''
          },

          {
            dialog: [
              {
                text: '<span class="underline blue--text cursor-pointer">Clique aqui e confira.</span>',
                action: 'faca-recarga'
              }
            ],
            i: 'Onde posso realizar a recarga?',
            text: ''
          },

          {
            i: 'Se tiver problema com a recarga o que eu faço?',
            text: 'Entrar em contato no telefone: 10699'
          },

          {
            i: 'Como aciono a assistência técnica?',
            text: `
              - Kit Antena + Receptor HD, Receptor HD + 2 meses de canais abertos e Receptor SD:<br>
              Telefone 0800-208-5005<br><br>
              - Receptor HD + 2 anos de canais abertos:<br>
              Whatsapp (11) 3198-0004
            `
          },

          {
            i: 'Tem diferença de antena conforme a região que moro?',
            text: 'Sim, em todos os estados é a antena de 60cm, exceto AM, AP, MT, PA e RR que é necessário utilizar a antena de 90cm.'
          },

          {
            i: 'Os produtos têm garantia?',
            text: `
              Sim, de 12 meses, direto com os fabricantes:<br>
              - Kit Antena + Receptor HD, Receptor HD + 2 meses de canais abertos e Receptor SD:<br>
              Telefone 0800-208-5005<br><br>
              - Receptor HD + 2 anos de canais abertos:<br>
              Whatsapp (11) 3198-0004
            `
          }
        ]
      }
    },

    methods: {
      ...mapActions('Dialogs', [ 'openFaq' ]),

      goToAnchorDelay (anchor, time) {
        setTimeout(() => {
          this.$vuetify.goTo(anchor)
        }, time)
      }
    }
  }
</script>
