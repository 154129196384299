import api from '@/plugins/axios/api'

export default {
  methods: {
    getNow () {
      // Format 2019-11-29T23:59:59.000-03:00

      api.get('get/datetime')
        .then(response => {
          return response.data.datetime
        })

      return new Date()
    },

    epochAlteracaoValorRecarga () {
      let Agora = new Date().getTime()
      let Inicio = new Date('2021-09-01T00:00:00.000-03:00').getTime()
      if (window.env('development') && (location.search && (/debug/.test(location.search) && /valorrecarga/i.test(location.search)))) {
        return true
      }

      return (Agora >= Inicio)
    }
  }
}
