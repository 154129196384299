<template>
  <v-card
    color="white"
    tile
    flat
    style="font-family:var(--font-family-highlight)"
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        text-xs-center
        xs10
      >
        <strong
          v-font-size="18"
          class="font-weight-bold text-uppercase"
          v-text="'Confira os pacotes e veja qual mais combina com você!'"
        />
      </v-flex>

      <v-flex
        xs12
        py-3
      />

      <v-flex
        xs12
        md10
        lg8
      >
        <v-layout
          justify-center
          wrap
        >
          <v-flex
            xs11
            md6
          >
            <v-layout
              justify-center
              wrap
            >
              <v-flex
                text-xs-center
                xs12
              >
                <v-item-group
                  v-model="escolha.canais.pacote"
                  mandatory
                >
                  <v-layout
                    wrap
                    justify-center
                  >
                    <v-flex
                      v-for="(canal, identificador) in lista.canais.cards"
                      :key="`lista-canais-claro-tv-${canal.title}`"
                      xs3
                      px-1
                    >
                      <v-item
                        :value="identificador"
                      >
                        <v-card
                          slot-scope="{ active, toggle }"
                          tile
                          flat
                          class="overflow-hidden pb-2"
                          @click="toggle"
                        >
                          <v-layout
                            wrap
                            justify-center
                          >
                            <v-flex
                              py-2
                              xs12
                              style="background-color: #EF2222; border-radius: 6px 6px 0 0; border: 2px solid #EF2222"
                            >
                              <strong
                                :class="`white--text font-size-${$vuetify.breakpoint.xsOnly ? 18 : 22} font-weight-bold`"
                                v-text="canal.title"
                              />
                            </v-flex>

                            <v-flex
                              v-ripple
                              xs12
                              py-2
                              :style="`border-radius: 0 0 6px 6px; border-top-width: 0px; border-bottom-width: 2px; border-left-width: 2px; border-right-width: 2px; border-style: solid; border-color: ${active ? '#EF2222' : '#D6D6D6'}`"
                            >
                              <v-layout
                                wrap
                                justify-center
                              >
                                <v-flex
                                  xs12
                                >
                                  <span
                                    :class="`font-size-${$vuetify.breakpoint.xsOnly ? 10 : 16}`"
                                    v-html="`mais de<br> ${canal.quantidade} canais*`"
                                  />
                                </v-flex>

                                <v-flex
                                  xs10
                                >
                                  <v-divider class="my-2 black" />
                                </v-flex>

                                <v-flex
                                  xs12
                                >
                                  <v-layout
                                    wrap
                                  >
                                    <v-flex
                                      v-for="canalpacote in canal.canais"
                                      :key="`lista-canais-pacote-claro-tv-${canalpacote}`"
                                      xs12
                                      py-2
                                    >
                                      <v-img
                                        :src="require(`@/assets/images/faq/canaisalacarte/${canalpacote}?lazy`)"
                                        height="30"
                                        width="46"
                                        class="mx-auto"
                                        contain
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs10
                                />

                                <v-flex
                                  xs12
                                />
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-item>
                    </v-flex>
                  </v-layout>
                </v-item-group>
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs11
                md10
                text-xs-center
              >
                <span
                  class="font-size-11"
                  style="color: #A2A2A2"
                  v-text="'Canais em HD em todos os pacotes de recarga.'"
                />
              </v-flex>

              <v-flex
                xs11
                md10
                text-xs-center
              >
                <span
                  class="font-size-11"
                  style="color: #A2A2A2"
                  v-text="'*Consulte a disponibilidade do canal Globo para a sua cidade no site www.claro.com.br.'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs8
                text-xs-center
              >
                <v-dialog
                  ref="dialogo_recarga_com_aparelho"
                  v-model="dialogo_recarga_com_aparelho"
                  content-class="flat fix-dialog-fullscreen--with-overlay"
                  fullscreen
                >
                  <template
                    v-slot:activator="{ on }"
                  >
                    <v-btn
                      v-show="false"
                      round
                      depressed
                      large
                      block
                      color="#FFD21E"
                      v-on="on"
                      v-text="'Recarregar'"
                    />

                    <v-btn
                      round
                      depressed
                      large
                      block
                      color="#FFD21E"
                      href="https://clarorecarga.claro.com.br/tv/login"
                      target="_blank"
                      v-text="'Recarregar'"
                    />
                  </template>

                  <v-layout
                    fill-height
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs12
                    >
                      <v-card
                        color="white"
                        width="344"
                        height="261"
                        class="mx-auto py-4"
                      >
                        <v-toolbar
                          absolute
                          color="transparent"
                          height="38"
                          flat
                        >
                          <v-spacer />

                          <v-btn
                            icon
                            @click="$refs.dialogo_recarga_com_aparelho.save()"
                          >
                            <v-icon
                              color="#D6D6D6"
                            >
                              close
                            </v-icon>
                          </v-btn>
                        </v-toolbar>

                        <v-layout
                          justify-center
                          wrap
                        >
                          <v-flex
                            xs10
                            text-xs-center
                          >
                            <strong
                              class="font-size-18 font-weight-bold"
                              v-text="`Para fazer a recarga, você precisa primeiro comprar seu receptor e sua antena`"
                            />
                          </v-flex>

                          <v-flex
                            xs12
                            py-3
                          />

                          <v-flex
                            xs12
                            text-xs-center
                          >
                            <span
                              class="font-size-16 font-weight-regular"
                              v-text="`Deseja continuar para página de recarga?`"
                            />
                          </v-flex>

                          <v-flex
                            xs12
                            py-1
                          />
                        </v-layout>

                        <v-card-actions
                          class="ma-3"
                        >
                          <v-btn
                            color="red"
                            dark
                            large
                            round
                            depressed
                            block
                            @click="$refs.dialogo_recarga_com_aparelho.save()"
                            v-text="`Voltar`"
                          />

                          <v-spacer class="max-width-20" />

                          <v-btn
                            color="grey"
                            large
                            round
                            depressed
                            block
                            outline
                            outlined
                            href="https://appclarotvprepago.visiontec.com.br"
                            target="_blank"
                            v-text="`continuar`"
                          />
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex
            xs10
            md6
          >
            <v-layout
              justify-end
              wrap
            >
              <v-flex
                style="border-bottom: 1px solid #e9e9e9"
                pb-3
                xs12
                md11
              >
                <strong
                  class="font-size-21 font-weight-bold"
                  style="color: #DE1717"
                  v-text="`Canais / Pacote ${lista.canais.cards[escolha.canais.pacote].title}`"
                />
                <br>
                <small
                  class="font-size-14"
                  style="color: #BBBBBB"
                  v-text="'Todos os Canais de TV Aberta + Outras Opções com Qualidade Digital.'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs12
                md11
              >
                <v-layout
                  justify-space-between
                >
                  <v-flex
                    v-for="{dias,valor} in lista.canais.cards[escolha.canais.pacote].recarga"
                    :key="`pacote-de-recarga-${dias}`"
                    shrink
                    pr-1
                  >
                    <v-card
                      style="maxc-width:84px;height:49px;background:#FFFFFF 0% 0% no-repeat padding-box;border:2px solid #EF2222;border-radius:6px"
                      flat
                    >
                      <v-layout
                        fill-height
                        align-center
                        justify-center
                        text-xs-center
                        wrap
                      >
                        <v-flex
                          xs12
                        >
                          <strong>
                            {{ dias }} dias
                          </strong>
                        </v-flex>

                        <v-flex
                          xs10
                        >
                          <v-divider
                            class="black"
                          />
                        </v-flex>

                        <v-flex
                          xs12
                        >
                          <span>
                            R$ {{ valor }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                :class="$vuetify.breakpoint.mdAndUp && 'height-252 overflow-auto'"
                xs12
                md11
              >
                <v-layout
                  wrap
                >
                  <v-flex
                    xs12
                  >
                    <v-expansion-panel
                      class="flat"
                      style="border-bottom: 1px solid #e9e9e9"
                    >
                      <v-expansion-panel-content
                        class="fix-expansion-panel transparent"
                      >
                        <template
                          v-slot:header
                        >
                          <strong
                            class="font-size-16 font-weight-medium"
                            v-text="`Canais abertos`"
                          />
                        </template>

                        <v-layout
                          wrap
                        >
                          <v-flex
                            v-for="next in lista.canais.imagens.filter(o => o.categoria === 'aberto' && o.pacote === 'inicial')"
                            :key="`pacote-${next.pacote}-canais-${next.categoria}-${next.index}`"
                            xs3
                            pa-1
                          >
                            <v-img
                              :src="next.src"
                              width="48"
                              height="48"
                              class="mx-auto"
                              contain
                            />
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
                  <v-flex
                    v-for="canal in splitCategoriasCanais(escolha.canais.pacote)"
                    v-show="canal !== 'aberto'"
                    :key="`canais-${canal}-do-pacote-${escolha.canais.pacote}`"
                    xs12
                  >
                    <v-expansion-panel
                      class="flat"
                      style="border-bottom: 1px solid #e9e9e9"
                    >
                      <v-expansion-panel-content
                        class="fix-expansion-panel transparent"
                      >
                        <template
                          v-slot:header
                        >
                          <strong
                            class="font-size-16 font-weight-medium"
                            v-text="`Canais ${canal}`"
                          />
                        </template>

                        <v-layout
                          wrap
                        >
                          <v-flex
                            v-for="next in lista.canais.imagens.filter(o => o.categoria === canal && o.pacote === escolha.canais.pacote)"
                            :key="`pacote-${next.pacote}-canais-${next.categoria}-${next.index}`"
                            xs3
                            pa-3
                          >
                            <v-img
                              :src="next.src"
                              width="60"
                              height="60"
                              class="mx-auto"
                              contain
                            />
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                py-4
                xs12
              />

              <v-flex
                xs12
                md11
              >
                <v-layout
                  wrap
                >
                  <v-flex xs12>
                    <strong
                      v-font-size="18"
                      v-text="'Canais adicionais a la carte:'"
                    />
                  </v-flex>

                  <v-flex xs3>
                    <v-img src="/img/canais/alacarte/claro-tvpre-canal-alacarte-telecine.png" />
                  </v-flex>
                  <v-flex xs3>
                    <v-img src="/img/canais/alacarte/claro-tvpre-canal-alacarte-premiere.png" />
                  </v-flex>
                  <v-flex xs3>
                    <v-img src="/img/canais/alacarte/claro-tvpre-canal-alacarte-sexyhot.png" />
                  </v-flex>
                  <v-flex xs3>
                    <v-img src="/img/canais/alacarte/claro-tvpre-canal-alacarte-combate.png" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<style lang="stylus">
  .fix-dialog-fullscreen
    &--with-overlay::before
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      overflow hidden
      opacity 1
      background-color #212121

  .fix-expansion-panel
    .v-expansion-panel__header
      min-height 62px
      padding 14px
</style>

<script>
  import epoch from '@/plugins/mixins/epoch'
  export default {
    mixins: [
      epoch
    ],

    data: function () {
      return {
        dialogo_recarga_com_aparelho: false,
        escolha: {
          canais: {
            pacote: 'tophd'
          }
        },
        lista: {
          canais: {
            imagens: [],
            cards: {
              inicial: {
                title: 'Inicial',
                canais: [
                  'inicial_aberto_0.png',
                  'inicial_aberto_3.png',
                  'inicial_aberto_2.png'
                ],
                quantidade: '40',
                recarga: [{ dias: 30, valor: 20 }, { dias: 60, valor: 35 }, { dias: 90, valor: 51 }, { dias: 180, valor: 82 }]
              },
              lighthd: {
                title: 'Light',
                canais: [
                  'lighthd_infantis_1.png',
                  'lighthd_esportes_13.png',
                  'lighthd_filmes-series_14.png'
                ],
                quantidade: '100',
                recarga: [{ dias: 30, valor: 60 }]
              },
              mixhd: {
                title: 'Mix',
                canais: [
                  'mixhd_infantis_29.png',
                  'mixhd_filmes-series_56.png',
                  'mixhd_esportes_40.png'
                ],
                quantidade: '140',
                recarga: [{ dias: 30, valor: 90 }]
              },
              tophd: {
                title: 'Top',
                canais: [
                  'tophd_infantis_62.png',
                  'tophd_filmes-series_81.png',
                  'tophd_esportes_79.png'
                ],
                quantidade: '160',
                recarga: [{ dias: 30, valor: 110 }]
              }
            }
          }
        }
      }
    },

    mounted () {
      this.listaCategoriasCanais()
    },

    methods: {
      splitCategoriasCanais (tab) {
        if (tab === 'all') return [...new Set((this.lista.canais.imagens.filter(o => o.pacote)).map(o => o.categoria))]
        return [...new Set((this.lista.canais.imagens.filter(o => o.pacote === tab)).map(o => o.categoria))]
      },

      listaCategoriasCanais () {
        const imagens = require.context('@/assets/images/faq/canaisalacarte/', true, /\.png$/)

        function splitKey (key, i) {
          return key.replace(/.png|.\//g, '').split('_')[i]
        }

        imagens.keys().forEach(key => (this.lista.canais.imagens.push({
          src: imagens(key),
          pacote: splitKey(key, 0),
          categoria: splitKey(key, 1),
          index: splitKey(key, 2)
        })))
      }
    }
  }
</script>
